<template>
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    :class="[customClass.toString()]"
  >
    <path
      d="M33.295 38.772c.347-.082.62-.283.82-.605.199-.322.258-.656.176-1.004l-5.21-22.139a1.283 1.283 0 0 0-.605-.82 1.283 1.283 0 0 0-1.003-.175 1.28 1.28 0 0 0-.82.605c-.2.322-.258.656-.176 1.003l5.21 22.14c.08.346.283.62.604.819.322.2.657.258 1.004.176Zm-8.388-3.524c.347-.081.62-.283.82-.605.198-.322.257-.656.175-1.003l-2.757-11.721a1.284 1.284 0 0 0-.606-.82 1.284 1.284 0 0 0-1.003-.176 1.29 1.29 0 0 0-.82.605 1.284 1.284 0 0 0-.176 1.004l2.758 11.72c.082.348.283.621.605.82.322.2.656.258 1.004.176Zm-7.738-3.677a1.28 1.28 0 0 0 .82-.605c.2-.322.258-.656.176-1.003l-.306-1.303a1.283 1.283 0 0 0-.605-.82 1.283 1.283 0 0 0-1.004-.175c-.347.081-.62.283-.82.605a1.281 1.281 0 0 0-.176 1.003l.307 1.303c.081.347.283.62.605.82.322.198.656.257 1.003.175Zm22.063.307c.347-.082.62-.284.82-.606.199-.321.257-.656.176-1.003l-2.758-11.72a1.284 1.284 0 0 0-.605-.82 1.284 1.284 0 0 0-1.004-.177c-.347.082-.62.284-.82.606a1.281 1.281 0 0 0-.176 1.003l2.758 11.72c.082.348.284.621.605.82.322.2.657.258 1.004.177Zm5.285-6.742c.348-.081.621-.283.82-.605.2-.322.258-.656.176-1.003l-.306-1.303a1.284 1.284 0 0 0-.605-.82 1.284 1.284 0 0 0-1.004-.176 1.29 1.29 0 0 0-.82.605 1.289 1.289 0 0 0-.176 1.004l.307 1.302c.081.348.283.62.605.82.322.2.656.258 1.003.176ZM10.59 59.096.354 15.599c-.235-.998-.056-1.99.537-2.978.592-.987 1.41-1.603 2.45-1.848L47.62.354c.998-.235 1.994-.045 2.986.57.992.613 1.605 1.42 1.84 2.418l7.967 33.86c.235.998.046 1.993-.569 2.985-.614.992-1.42 1.606-2.418 1.84l-37.767 8.887-5.427 8.768c-.465.75-1.137 1.058-2.017.921-.88-.137-1.421-.639-1.626-1.507Zm2.789-5.673 3.734-6.033 39.394-9.27L48.54 4.262 4.261 14.68l9.117 38.743ZM4.26 14.68l9.117 38.743L4.26 14.68Z"
      fill="#32393F"
    />
  </svg>
</template>

<script>
export default {
  name: "Iconconvo",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
